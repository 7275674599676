// json Converter
function jsonConvert (string) {
	return JSON.parse('{"' + string.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
}

;(function($) {

    $.lead = function(options) {

        var defaults = {
            endpoint        : '',
            apikey          : '',
            callbackSuccess : function(){},
            callbackFailure : function(){ alert('Oops, something went wrong!');}
        }

        var plugin = this;

        plugin.settings = {}

        var init = function() {
            plugin.settings = $.extend({}, defaults, options);
        }

        plugin.create = function(data) {
            data = data +'&'+ $.param({'apikey': plugin.settings.apikey});

            $.ajax({
                type: 'POST',
                url: plugin.settings.endpoint,
                data: data,
                dataType: 'json'
            }).done(function (data) {
                plugin.settings.callbackSuccess(data);
            }).fail(function (data) {
                plugin.settings.callbackFailure(data);
            });
        }

        init();
    }

})(jQuery);
